import styled from '@emotion/styled';
import { Flex, TextStyled, theme } from '../components';
import DefaultButton from './DefaultButton';

const StyledCard = styled(Flex)`
  position: relative;
  width: 500px;
  height: 300px;
  box-shadow: 0px 0px 50px rgba(49, 153, 152, 0.4);
  border-top: 6px solid ${theme.colors.defaultButton};
  border-radius: 6px;
  justify-content: center;
  z-index; 3;
  background: white;
`;

const StyledButtons = styled(Flex)`
  width: 100%;
  justify-content: center;
`;

const Card = ({ children, changeStep, patientWindowFullScreen, buttonText, disableButton }) => {
  const customButtonClick = (event) => {
    if (disableButton === true) {
      event.target.innerHTML = 'Please wait...';
      event.target.disabled = true;
    }
    changeStep(event);
  };
  return (
    <StyledCard>
      <Flex width='300px' flexDirection='column' justifyContent='center' alignItems='center' padding='2em 0em'>
        {children}
        <StyledButtons>
          <DefaultButton
            width='100%'
            borderBottom={`4px solid ${theme.colors.buttonBorder}`}
            onClick={(event) => customButtonClick(event)}
            bg={theme.colors.defaultButton}
            uppercase
            disabled={!patientWindowFullScreen}
          >
            {buttonText}
          </DefaultButton>
        </StyledButtons>
      </Flex>
    </StyledCard>
  );
};

export default Card;
