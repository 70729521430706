var basePath = '';

var environmentFrontEnd = {
  prodBuild: 'https://staging.ibisvision.co.uk' + basePath,
  stagingBuild: 'https://pulsekiosk.ibisvision.co.uk' + basePath,
  localBuild: 'http://localhost:3000' + basePath,
};

// ET - add backend urls here once integrated
var environmentApiUrl = {
  productionBuild: 'https://staging-api.ibisvision.co.uk/api/v1/login',
  stagingBuild: 'https://dev.ibisvision.co.uk/api/v1/',
  localBuild: '',
};

// ENV CHANGE
const frontEnd = environmentFrontEnd.stagingBuild;
const api = environmentApiUrl.stagingBuild;

// DEVICE SCREEN SIZES

export const physicalScreenWidth = 296;
export const physicalScreenHeight = 527;
export const amslerRequiredGridSize = 100;

export const baseUrl = frontEnd;
export const apiUrl = api;
export const urlBasePath = basePath;

// CHANGE THIS CONSTANT TO TRUE WHEN USING THE BROWSER EXTENSION
export const useBrowserExtension = true;

// WS -  DEFAULT VALUES FOR DUMMY KIOSK
export const staticClientValues = {
    'user_email': 'pulse-user@ibisvision.co.uk',
    'user_password': 'PulseUser123!',
    'user_id': 12,
    'company_id': 4,
    'pulse_api_url': 'https://bettersleep20200919112708.azurewebsites.net/bettersleep.asmx/piv_sendresult',
    "patient_dob": '2000-05-21T00:00:00.000Z',
    "patient_title": 'Mr',
    "patient_first_name": 'Kiosk',
    "patient_last_name": 'Auth v1.2' + ' ' + navigator.platform,
    "test_result_defects": 'You have reported issues during your eye test. You should see a doctor.',
    "test_result_no_defects": 'You have not reported any issues during the eye test.',
    "defect_size": 80,
    "grid_size_ros": 378
}
