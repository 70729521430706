import { StyledButton } from './design-system';

const DefaultButton = ({ children, text, onClick, after, grad, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

export default DefaultButton;
