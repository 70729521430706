import { ThemeProvider } from '@emotion/react';
import { lazy, Suspense, useEffect } from 'react';
import { useUser } from './context/userContext';
import { theme } from './components';
import { useAuth } from './context/authContext';
import { usePatient } from './context/patientContext';

const loadAuthenticatedApp = () => import('./authenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./unauthenticatedApp'));

const App = () => {
  const user = useUser();
  const patient = usePatient();
  const { isAuthenticated } = useAuth();

  console.log('Am I authenticated? ' + isAuthenticated);

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback=' '>{user && isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Suspense>
    </ThemeProvider>
  );
};

export default App;
