import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import { apiUrl } from '../_common/config';

// ET - TODO: update the below to include the /kiosk that will be on kiosk endpoints
const baseUrl = `${apiUrl}`;

const userSubject = new BehaviorSubject(null);

// ET - TODO: this will take a token passed in via url params from login page rather
// than email & password
const login = (email: string, password: string) => {
  return fetchWrapper.post(`${baseUrl}/login`, { email, password })
    .then(user => {
      localStorage.setItem('__ibis_token__', JSON.stringify(user));
      userSubject.next(user);
      // Now call create patient => 
        // if successful, pass back success to login page
        // if error
          // if error = patient already exists, do put
          // otherwise, clear user and token, and start auth process again
        return user;
    });
}

const logout = () => {
  // ET - TODO: logout
}

export const accountService = {
  login,
  logout,
  user: userSubject.asObservable(),
  get userValue() { return userSubject.value }
};