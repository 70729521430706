import styled from '@emotion/styled';
import { Flex, theme } from '../components';
import DefaultButton from './DefaultButton';

const StyledCard = styled(Flex)`
  position: relative;
  width: 800px;
  height: 450px;
  box-shadow: 0px 0px 50px rgba(49, 153, 152, 0.4);
  border-top: 6px solid ${theme.colors.defaultButton};
  border-radius: 6px;
  justify-content: center;
  z-index; 3;
  background: white;
`;

const StyledButtons = styled(Flex)`
  width: 90%;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
`;

const Card = ({ children, changeStep, backStep, patientWindowFullScreen }) => {
  return (
    <StyledCard>
      <Flex width='630px' flexDirection='column' justifyContent='center' alignItems='center'>
        {children}
        <StyledButtons>
          <DefaultButton width='25%' onClick={backStep} bg={theme.colors.secondaryButton} color='black' uppercase>
            Back
          </DefaultButton>
          <DefaultButton
            width='25%'
            borderBottom={`4px solid ${theme.colors.buttonBorder}`}
            onClick={() => {
              changeStep();
            }}
            bg={theme.colors.defaultButton}
            uppercase
            disabled={!patientWindowFullScreen}
          >
            Next
          </DefaultButton>
        </StyledButtons>
      </Flex>
    </StyledCard>
  );
};

export default Card;
