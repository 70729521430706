import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Mulish', 'sans-serif'],
  },
});

export const theme = {
  breakpoints: ['40em', '52em', '64em', '80em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 36, 48, 80, 96],
  headingSizes: [24, 36, 48, 64, 96],
  fontWeights: [400, 700],
  sizes: {
    divider: [1, 2, 3, 4],
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    serif: 'EB Garamond, georgia, times, serif',
    sansSerif: 'Mulish,  BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    primary: '#319998',
    background: '#E3F1F1',
    defaultButton: '#6DC89D',
    buttonBorder: '#269561',
    backButton: '#E3F1F1',
    header: '#CED9D9',
    wavyButton: '#6DC89D',
    blurryButton: '#B06DC8',
    missingButton: '#D39144',
  },
};

export default theme;
