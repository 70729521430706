import {
  background,
  borderColor,
  space,
  color,
  layout,
  grid,
  alignItems,
  border,
  borderRight,
  typography,
  flexWrap,
  flexDirection,
  justifyContent,
  flex,
  flexBasis,
  alignSelf,
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas,
  gridAutoFlow,
  gridArea,
  gridColumn,
  position,
  boxShadow,
} from 'styled-system';

import { theme } from './theme';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fadeLoop = keyframes`
0% {
  opacity: 0.05;
}
100% {
  opacity: 1;
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  ${space}
  ${layout}
        ${flex}
        ${flexDirection}
`;

export const StyledImg = styled.img`
  ${layout}
  ${space}
${(props) =>
    props.center &&
    `
  object-fit: cover;  
  object-position: center;  
`}
`;

export const AnimatedImg = styled.img`
  ${layout}
  ${space}
  animation: ${fadeLoop} 1.5s infinite alternate;
  ${(props) =>
    props.center &&
    `
  object-fit: cover;  
  object-position: center;  
`}
`;

export const Textarea = styled.textarea`
  ${space}
  ${color}
        ${typography}
        ${layout}
        ${border}
        resize: none;
  ${(props) =>
    props.default &&
    `
          font-size: 16px;
          height: 100px;
          padding: 10px 15px;
          color: #000000;
          border-radius: 2px;
          border:  1px solid #DEDEDE;
          font-family: ${theme.fonts.sansSerif};
        `}
  &:read-only {
    border: 0px;
    padding: 0px;
  }
`;

export const Input = styled.input`
  padding: 0px;
  box-sizing: border-box;
  ${(props) =>
    props.default &&
    `
        padding: 10px 15px;
        color: #000000;
        border-radius: 2px;
        border: 1px solid #DEDEDE;
        height: 56px; 
      `}
  ${space}
      ${color}
      ${typography}
      ${layout}
      ${alignSelf}
      ${border}
      ${flex}
      ${flexBasis}
      &:focus {
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  ${space}
  ${color}
      ${typography}
      ${flex}
      ${gridArea}
      span {
    margin-bottom: 5px;
    color: ${theme.colors.primary};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }
`;

export const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  border,
  borderColor,
  borderRight,
  space,
  color,
  layout,
  alignItems,
  grid,
  gridArea,
  gridColumn,
  flex,
  justifyContent,
  position,
  boxShadow,
  background,
);

export const Flex = styled(Box)(
  {
    display: 'flex',
  },
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  gridArea,
  gridColumn,
  position,
  layout,
  background,
);

Flex.displayName = 'Flex';

export const Grid = styled(Box)`
  display: grid;
  ${(props) =>
    props.scroll &&
    `
            scroll-snap-type: x mandatory !important;
            scrollbar-width: none;
            scroll-padding: 0px 24px;
            padding: 4px 24px 20px;
            margin: 0px -24px;
            grid-auto-flow: column !important;
            overflow-x: auto !important;
            overflow-y: hidden !important;
            scroll-snap-type: x mandatory !important;        
            &::-webkit-scrollbar{
              display: none;
            }
          `}
  ${gridTemplateRows}
        ${gridTemplateColumns}
        ${gridTemplateAreas}
        ${gridAutoFlow}
        ${flex}
`;

Flex.displayName = 'Grid';

export const Heading = styled.h1(
  {
    margin: 0,
  },
  layout,
  color,
  space,
  typography,
  border,
  gridArea,
);

export const TextStyled = styled.p`
  color: black;
  font-size: 20px;
  ${space}
  ${color}
      ${typography}
      ${layout}
      ${border}
      ${(props) => props.uppercase && `text-transform: uppercase`}
`;

export const LinkStyled = styled.a`
  color: grey;
  font-size: 12px;
  ${space}
  ${color}
      ${typography}
      ${layout}
      ${alignSelf}
      ${(props) => props.uppercase && `text-transform: uppercase`}
`;

export const StyledButton = styled.button`
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 0;
  color: white;
  text-align: center;
  padding: 16px 30px;
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease-in-out;
  text-transform: ${(props) => (props.uppercase ? `uppercase` : 'none')};
  ${background}
  ${space}
      ${border}
      ${color}
      ${typography}
      ${layout}
      ${flex}
      ${flexDirection}
      ${alignSelf}
      ${position}
      ${boxShadow}
      ${gridColumn}
      ${(props) =>
    props.as === 'a' &&
    `    
        text-decoration: none;                                         
      `}
      &:hover {
    cursor: pointer;
    background: ${(props) => props.bg};
    opacity: 0.9;
  }
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

export const DynamicComponent = styled.div(space, color, layout, grid, alignItems);

export const Divider = styled.div`
  ${space}
  ${color}
      ${layout}
      ${theme}
`;

export const TextBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${space}
  ${color}
      ${layout}
      ${border}
      ${typography}
      ${gridArea}
`;

export const TitleWithText = styled.div`
  .title {
    margin: 0;
    font-size: 25px;
    color: #72a826;
  }
  p {
    color: #818181;
    font-size: 14px;
  }
  ${(props) => props.border && `border-bottom: 1px solid #eee`}
`;
