import styled from '@emotion/styled';
import { Flex, theme } from '../components';
import DefaultButton from './DefaultButton';
import { TextStyled } from './design-system';

const StyledCard = styled(Flex)`
  position: relative;
  width: 800px;
  box-shadow: 0px 0px 50px rgba(49, 153, 152, 0.4);
  border-radius: 6px;
  justify-content: center;
  z-index; 3;
  background: white;
`;

const StyledButton = styled(Flex)`
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 30px;
`;

const DetailCard = ({ children, title, changeStep, patientWindowFullScreen, customHeight }) => {
  return (
    <StyledCard>
      <Flex width='100%' flexDirection='column' height={`${customHeight ? customHeight : '480px'}`}>
        <Flex bg={theme.colors.header} flexDirection='column'>
          <TextStyled color='white' fontWeight='bold' textAlign='center' uppercase>
            {title}
          </TextStyled>
        </Flex>
        <Flex width='100%' flexDirection='column' justifyContent='center'>
          {children}
          <StyledButton>
            <DefaultButton
              width='90%'
              padding='10px 10px'
              borderBottom={`4px solid ${theme.colors.buttonBorder}`}
              onClick={() => changeStep()}
              bg={theme.colors.defaultButton}
              uppercase
              disabled={!patientWindowFullScreen}
            >
              Next
            </DefaultButton>
          </StyledButton>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

export default DetailCard;
