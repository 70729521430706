import React, { createContext, useContext, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import jwt_decode from 'jwt-decode';
import { client } from '../_apiclient/api-client';
import { physicalScreenWidth, physicalScreenHeight, amslerRequiredGridSize, staticClientValues } from '../_common/config';


const sessionStorageKey = '__ibis_token__';

const getUser = () => {
  const user = JSON.parse(sessionStorage.getItem('__ibis_token__'));
  if (!user) {
    return null;
  }
  return user;
};

const getPatient = () => {
  const patient = JSON.parse(sessionStorage.getItem('__ibis_patient__'));
  if (!patient) {
    return null;
  }
  return patient;
};

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
    patient: null,
    companyId: null,
    error: null,
    status: null,
  });

  const getCompanyId = () => {
    const token = JSON.parse(sessionStorage.getItem('__ibis_token__'));
    if (!token) {
      return null;
    }
    const decoded = jwt_decode(token.jwtToken);
    const companyId = decoded.data.companyId;
    return companyId;
  };

  useEffect(() => {
    setState({ ...state, user: getUser(), patient: getPatient(), companyId: getCompanyId() });
  }, []);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const login = async (p_token) => {
    setState({ ...state, status: 'Authenticating...' });
    await delay(3000);
    // ET - TODO: update the below to hit correct endpoint with the token value once 500 is fixed on backend
    fetchWrapper.post('kiosk/session', { data: { token: p_token } }).then(
      (response) => {
        // ET - TODO: change the below to parse out user info and patient info
        // then call create patient using parsed payload
        const user = {
          jwtToken: response.data.token,
          userId: response.data.userId,
        };
        sessionStorage.setItem('__ibis_token__', JSON.stringify(user));
        // ET - TODO: change the below to use AuthStatus enum
        setState({ ...state, status: 'Creating patient...', user: user });
        createPatient(response.data.payload);
        navigate(`/`);
      },
      (error) => {
        // ET - TODO: switch over error
        setState({ ...state, status: 'Authorisation failed' });
      },
    );
  };

  // const login = async (email, password) => {
  //   setState({ ...state, status: 'Authenticating...' });
  //   await delay(3000);
  //   // OF USER:
  //   // kiosk@openfrequency.com
  //   // Kiosk123456
  //   client('login', { body: { data: { email: 'kiosk@openfrequency.com', password: 'Kiosk123456' } } }).then(
  //     (data) => {
  //       // const user = data;
  //       // localStorage.setItem('__ibis_token__', JSON.stringify(user.data));
  //       // setState({ ...state, status: 'creating patient' });
  //       // createPatient(user);
  //       const user = {
  //         jwtToken: data.data.token,
  //         userId: data.data.userId,
  //       };
  //       sessionStorage.setItem('__ibis_token__', JSON.stringify(user));
  //       // ET - TODO: change the below to use AuthStatus enum
  //       setState({ ...state, status: 'Creating patient...', user: user });
  //       createPatient(data.data.payload).then(console.log(state));
  //     },
  //     (error) => {
  //       setState({ ...state, error: error.message, status: null });
  //       console.error(error.message);
  //     },
  //   );
  // };

  const createPatient = async (payloadString) => {
    setState({ ...state, status: 'Creating Patient...' });
    await delay(3000);
    console.log(payloadString);
    fetchWrapper.post('patients', { data: { dateOfBirth: staticClientValues.patient_dob, title: staticClientValues.patient_title, firstName: staticClientValues.patient_first_name, lastName: staticClientValues.patient_last_name } }).then(
      (data) => {
        const patient = {
          patientId: data.data.id,
        };
        sessionStorage.setItem('__ibis_patient__', JSON.stringify(patient));
        sessionStorage.setItem('__test_result__', staticClientValues.test_result_no_defects);
        setState({ ...state, patient: patient, status: 'complete' });
        window.location.reload();
      },
      (error) => {
        console.log(error);
        setState({ ...state, error: error, status: 'Create patient failed', patient: null });
      },
    );
  };

  const submitTest = async (config) => {
    var pulseUrl = staticClientValues.pulse_api_url;
    var testResultForPulse = function () {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', pulseUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };

        var patientId = JSON.parse(sessionStorage.getItem('__ibis_patient__')).patientId;
        var testResult = sessionStorage.getItem('__test_result__');

        xhr.send(
          '{"str_piv_result":"{\\"userid\\":\\"' +
            JSON.parse(sessionStorage.getItem('__ibis_patient__')).patientId +
            '\\",\\"result\\":\\"' +
            testResult +
            '\\"}"}',
        );
      });
    };

    await testResultForPulse();

    var dateOfTest = new Date();

    var dateString = dateOfTest.getFullYear() + '-' + (dateOfTest.getMonth() + 1) + '-' + dateOfTest.getDate();

    fetchWrapper
      .post('tests', {
        data: {
          patientId: state.patient.patientId,
          technicianId: state.user.userId, //OF technician 7
          companyId: state.companyId, // OF company - 2
          testType: 'Amsler',
          glasses: 'none',
          leftEyeTested: true,
          rightEyeTested: true,
          leftEyeFirst: true,
          testDeviceConfiguration: {
            reportedScreenSize: {
              // screen dimensions in config are portrait, below is landscape
              width: physicalScreenHeight,
              height: physicalScreenWidth,
            },
            screenResolution: {
              width: window.screen.width,
              height: window.screen.height,
            },
            userAgent: 'Mozilla/5.0 (Windows NT 6.3; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.87 Safari/537.36',
          },
          eyes: {
            left: {
              testStartTime: dateString,
              results: config.left.length ? config.left : [{}],
              testConclusionTime: dateString,
            },
            right: {
              testStartTime: dateString,
              results: config.right.length ? config.right : [{}],
              testConclusionTime: dateString,
            },
          },
        },
      })
      .then(
        (data) => {
          document.dispatchEvent(new CustomEvent("ControlFinished"));
          window.sessionStorage.removeItem(sessionStorageKey);
          navigate(`/test-complete`);
        },
        (error) => {
          document.dispatchEvent(new CustomEvent("ControlFinished"));
          window.sessionStorage.removeItem(sessionStorageKey);
          console.log('hi, error');
        }
      );
  };

  const logout = async () => {
    window.sessionStorage.removeItem(sessionStorageKey);
    setState({ ...state, patient: null, user: null });
    // ET - TODO: add call to backend to logout as well
  };

  return (
    <AuthContext.Provider value={{ ...state, login, submitTest, logout, createPatient, setState }}>
      {state.status === '' ? (
        'Loading...'
      ) : state.status === 'error' ? (
        <div>
          Oh no
          <div>
            <pre>{state.error.message}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const state = useContext(AuthContext);
  let isAuthenticated = false;
  if (state.user && state.patient) {
    isAuthenticated = true;
  }

  if (window.location.pathname.search('patient-window') !== -1) {
    isAuthenticated = true;
    state.user = true;
  }

  return {
    ...state,
    isAuthenticated,
  };
};

export { AuthProvider, useAuth };
