import { accountService } from '../_services/account-service';
import { apiUrl } from '../_common/config';
import jwt_decode from 'jwt-decode';
 
export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete
}

const headers = 
    { 'Content-Type': 'application/json', 
      'Accept' : 'application/json' };

async function get(url: string): Promise<any> {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };
    const response = await fetch(apiUrl + url, requestOptions);
    return handleResponse(response);
}

async function post(url: string, body: {}): Promise<any> {
    const requestOptions = {
        method: 'POST',
        headers: {  ...authHeader(url) },
        body: JSON.stringify(body)
    };
    const response = await fetch(apiUrl + url, requestOptions);
    return handleResponse(response);
}

async function put(url: string, body: {}): Promise<any> {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(url) },
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);    
}

// prefixed with underscore because delete is a reserved word in javascript
async function _delete(url: string): Promise<any> {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

// helper functions

const authHeader = (url: string) => {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = JSON.parse(sessionStorage.getItem('__ibis_token__'));
    console.log(user)
    let sessionToken = '';
    if (user) {
        try {
            let decodedJwt: {data: {sessionToken}} = jwt_decode(user.jwtToken);
            sessionToken = decodedJwt.data.sessionToken;
        } catch(error) {
            console.log(error);
        }
    }
    const isLoggedIn = user && sessionToken;
    if (isLoggedIn) {
        return { ...headers, 
                 Authorization: `Token token=${sessionToken}` };
    } else {
        return { ...headers };
    }
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // ET - TODO: add return url
                accountService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}