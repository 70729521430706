import { createContext, useContext } from 'react';
import { useAuth } from './authContext';

const PatientContext = createContext();

const PatientProvider = props => {
  const {patient} = useAuth();

  return(
    <PatientContext.Provider value={patient} {...props} />
  )
}

const usePatient = () => useContext(PatientContext);

export { PatientProvider, usePatient };